import Glightbox from "glightbox/src/js/glightbox";

const { previous, next, close } = document.getElementById("db-glightbox-js").dataset;

const lightboxHTML = `<div id="glightbox-body" class="glightbox-container" tabindex="-1" role="dialog" aria-hidden="false">
<div class="gloader visible"></div>
<div class="goverlay"></div>
<div class="gcontainer">
<ul id="glightbox-slider" class="gslider"></ul>
<button class="gclose gbtn" aria-label="${close}" data-taborder="3"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
<path fill="#111" fill-rule="evenodd" d="M1.618.194 10 8.576 18.293.3a.957.957 0 0 1 .704-.299A.986.986 0 0 1 20 1.003c0 .26-.065.453-.29.68L11.423 10l8.285 8.317a.987.987 0 0 1 .291.68A.988.988 0 0 1 18.997 20c-.26 0-.453-.065-.68-.29L10 11.423 1.707 19.7a.957.957 0 0 1-.704.299A.988.988 0 0 1 0 18.997c0-.26.065-.453.29-.68L8.577 10 .291 1.683A.988.988 0 0 1 0 1.003C0 .453.42 0 1.003 0c.194 0 .42.065.615.194Z" clip-rule="evenodd"/>
</svg></button>
<button class="gprev gbtn" aria-label="${previous}" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 24">
<path fill="#EC0016" fill-rule="evenodd" d="M13.25 22c0 .703-.508 1.25-1.25 1.25a1.38 1.38 0 0 1-.899-.352l-10-10A1.376 1.376 0 0 1 .75 12c0-.313.117-.625.352-.899l10-10c.273-.234.585-.351.898-.351.312 0 .625.117.898.352.235.273.352.585.352.898 0 .313-.117.625-.352.898L3.758 12l9.14 9.101c.235.274.352.586.352.898Z" clip-rule="evenodd"/>
</svg></button>
<button class="gnext gbtn" aria-label="${next}" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 24">
<path fill="#EC0016" fill-rule="evenodd" d="M.75 22c0 .703.508 1.25 1.25 1.25.313 0 .625-.118.899-.352l10-10c.234-.273.351-.586.351-.898 0-.313-.117-.625-.352-.899l-10-10A1.377 1.377 0 0 0 2 .75c-.312 0-.625.117-.898.352A1.375 1.375 0 0 0 .75 2c0 .313.117.625.352.898L10.242 12l-9.14 9.1a1.378 1.378 0 0 0-.352.9Z" clip-rule="evenodd"/>
</svg></button>
</div>
</div>`;

const slideHTML = `<li class="gslide">
<div class="gslide-inner-content">
<div class="ginner-container">
<div class="gslide-media">
</div>
<div class="gslide-description">
<div class="gdesc-inner">
<h4 class="gslide-title"></h4>
<div class="gslide-desc"></div>
</div>
</div>
</div>
</div>
</li>`;

const lightbox = Glightbox({
  lightboxHTML,
  slideHTML,
  loop: true,
});

let activeElement;

lightbox.on("open", () => {
  activeElement = document.activeElement;
});

lightbox.on("close", () => {
  activeElement.focus();
});
